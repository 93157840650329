import Hammer from 'hammerjs';
import React from 'react';
// import GitHubButton from 'react-github-btn';
import './App.scss';
import Game2048, { Direction, Game2048Tile } from './lib/Game2048';
import SoundManager from './lib/SoundManager';
import StateManager from './lib/StateManager';
import VibratorManager from './lib/VibratorManager';

const getText = (value: number) => {
  const words = [
    '童生', '秀才', '举人', '贡士', // 2, 4, 8, 16
    '进士', '探花', '榜眼', '状元', // 32, 64, 128, 256
    // '跳', 'RAP', '篮球', 'MUSIC', // 512, 1024, 2048, 4096
    // '鸡', '你', '太', '美', // 8192, 16384, 32768, 65536
    // 'BABY', 'OH~', // 131072, undefined
  ];
  return words[Math.min(Math.log2(value), words.length) - 1];
};

const getNumber: (value: number) => string = (value: number) => {
  if (value >= 100000000) {
    return value.toPrecision(2);
  } else if (value >= 1000000) {
    return `${(value / 1000000).toPrecision(3)}M`;
  } else if (value >= 100000) {
    return `${(value / 1000).toPrecision(3)}K`;
  } else {
    return value.toString();
  }
};

interface TileProps {
  i: number,
  j: number,
  value: number,
  overlaid: boolean
}

class Tile extends React.Component<TileProps> {
  state = {
    pulse: false as boolean,
    value: this.props.value as number,
  };
  protected _unmounted = false;

  componentDidUpdate(prevProps: Readonly<TileProps>, prevState: Readonly<{ pulse: boolean, value: number }>) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        pulse: false,
      }, () => setTimeout(() => !this._unmounted && this.setState({
        pulse: true,
        value: this.props.value,
      }), 50));
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  render() {
    const { i, j, overlaid } = this.props;
    const { value } = this.state;
    let className = `game-tile pos-i-${i} pos-j-${j} tile-${value}`;
    overlaid && (className += ' tile-overlaid');
    this.state.pulse && (className += ' tile-pulse');
    return (
      <span className={className}>{getText(value)}</span>
    );
  }
}

class App extends React.Component {
  state = {
    tiles: [] as Game2048Tile[],
    score: 0 as number,
    best: 0 as number,
    scoreAdded: 0 as number,
    logoPulse: false,
    messageVisible: true as boolean,
    message: 'start' as 'start' | 'win' | 'win-game-over' | 'game-over',
  };

  protected containerRef: React.RefObject<HTMLDivElement>;
  protected game: Game2048 | null;
  protected hammer: HammerManager | null;
  protected soundManager: SoundManager | null;
  protected vibratorManager: VibratorManager;
  protected stateManager: StateManager;

  constructor(props: Readonly<{}>) {
    super(props);
    this.containerRef = React.createRef();
    this.game = null;
    this.hammer = null;
    this.soundManager = null;
    this.vibratorManager = new VibratorManager();
    this.stateManager = new StateManager();
  }

  componentDidMount(): void {
    window.addEventListener('keydown', this._moveK);
    this.hammer = new Hammer.Manager(this.containerRef.current as EventTarget, {
      recognizers: [[Hammer.Pan]],
    });
    this.hammer.on('panstart', (event) => this._moveG(event.direction));
    this.soundManager = new SoundManager({
      onPlay: () => {
        this.setState({
          logoPulse: true,
        });
      },
      onEnded: () => {
        this.setState({
          logoPulse: false,
        });
      },
    });
    this._tryResumeGame();
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this._moveK);
    if (this.hammer) {
      this.hammer.off('panstart');
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="app">
          <header className="header">
            
            <h1 className={this.state.logoPulse ? 'logo-pulse' : undefined} aria-label="科举之路">
            科举之路
            </h1>
          </header>
          <section className="actions">
            <div className="new-game-wrapper">
              <button className="new-game" onClick={this._newGame}>开始科举之路</button>
            </div>
            <div className="hint">移动、合并相同方块。目标是: <strong>状元</strong> !</div>
          </section>
          <section className={`game${this.state.messageVisible ? ' has-message' : ''}`}>
            <div className="game-container" ref={this.containerRef}>{
              [0, 1, 2, 3].map(i => [0, 1, 2, 3].map(j =>
                <span className={`game-cell pos-i-${i} pos-j-${j}`} key={`${i}-${j}`} />,
              ))
            }{
              this.state.tiles.map(({ i, j, value, key, overlaid }) =>
                <Tile i={i} j={j} value={value} key={key} overlaid={overlaid} />,
              )
            }</div>
            {
              this.state.message === 'start' &&
              <div className="game-message" role="button" aria-label="隐藏消息" onClick={this._newGame}>
                {/* <div className="start" role="img" aria-label="耳机" /> */}
                <h2>开始科举之路!</h2>
                <p>点按以开始<br /></p>
              </div>
            }{
            this.state.message === 'win' &&
            <div className="game-message" role="button" aria-label="隐藏消息" onClick={this._newGame}>
              {/* <div className="win" role="img" aria-label="状元" /> */}
              <h2>恭喜你科举之路成功了!</h2>
              <p>点按以重新开始</p>
            </div>
          }{
            this.state.message === 'win-game-over' &&
            <div className="game-message" role="button" aria-label="隐藏消息" onClick={() => this.setState({
              messageVisible: false,
            })}>
              {/* <div className="win" role="img" aria-label="状元" /> */}
              <h2>你成功了!</h2>
           
            </div>
          }{
            this.state.message === 'game-over' &&
            <div className="game-message" role="button" aria-label="隐藏消息" onClick={this._newGame}>
              {/* <div className="game-over" role="img" aria-label="菜" /> */}
              <h2>科举之路失败!</h2>
              <p>点按以重新开始</p>
            </div>
          }</section>
          <section className="introduction">
            <div className="how-to">
              1.点击屏幕右上角“开始科举之路”启动游戏；<br />
              2.游戏参考资料（《明清科举考试系统简图》科考等级：童生→秀才→举人→贡士→进士→探花→榜眼→状元）；<br />
              3.手指引导方块移动，同科考等级的相邻两方块相撞可晋升到下一等级，直至出现状元时，游戏胜利；<br />
              4.当游戏界面全部被方块填满时，游戏结束。<br />
         
              {/* 玩法：<strong>用手指滑动</strong> 来移动方块，<br />两个相同的方块可以合成新方块。<br /> */}
              {/* <span className="about-arrow">关于</span> */}
            </div>
          </section>
        
        </div>
        <div className="about">
      
        </div>
      </React.Fragment>
    );
  }

  protected _newGame = (event?: React.MouseEvent<HTMLElement>) => {
    if (event != null) {
      event.currentTarget.blur();
    }
    setTimeout(() => {
      if (this.soundManager != null) {
        this.soundManager.play(2);
        this._createGame();
      }
    }, this.state.messageVisible ? 250 : 50);
    this.setState({
      score: 0,
      messageVisible: false,
    });
    // @ts-ignore
    window.gtag('event', 'new_game');
  };

  protected _tryResumeGame = () => {
    const { best, score, game } = this.stateManager.getState();
    this.setState(Object.assign({
      best,
      score,
    }, game != null ? {
      messageVisible: false,
    } : {}));
    if (game != null) {
      // @ts-ignore
      window.gtag('event', 'resume_game', {
        score: score,
        best: best,
      });
      this._createGame(game);
    }
  };

  protected _createGame = (state: number[] | null = null) => {
    let firstUpdate = true;
    this.game = new Game2048({
      onUpdate: tiles => {
        this.setState({ tiles });
        if (!firstUpdate || state == null) {
          this.vibratorManager.vibrateShort(1);
        }
        firstUpdate = false;
      },
      onScoreAdd: value => {
        this.setState({
          score: this.state.score + value,
          scoreAdded: 0,
          best: Math.max(this.state.score + value, this.state.best),
        }, () => setTimeout(() => this.setState({
          scoreAdded: value,
        }), 50));
      },
      onMaxMerge: value => {
        if (this.soundManager != null) {
          this.soundManager.play(value);
          this.vibratorManager.vibrateShort(2);
        }
      },
      on2048: gameOver => {
        this.vibratorManager.vibrateLong();
        this.setState({
          messageVisible: true,
          message: gameOver ? 'win-game-over' : 'win',
        });
        // @ts-ignore
        window.gtag('event', 'win');
        if (gameOver) {
          // @ts-ignore
          window.gtag('event', 'game_over', {
            score: this.state.score,
            best: this.state.best,
          });
        }
      },
      onGameOver: () => {
        this.vibratorManager.vibrateLong();
        this.setState({
          messageVisible: true,
          message: 'game-over',
        });
        // @ts-ignore
        window.gtag('event', 'game_over', {
          score: this.state.score,
          best: this.state.best,
        });
      },
      onStateChanged: newState => {
        this.stateManager.setState({
          best: this.state.best,
          score: newState != null ? this.state.score : 0,
          game: newState,
        });
      },
      state,
    });
  };

  protected _moveK = (event: KeyboardEvent) => {
    if (this.game != null && !this.state.messageVisible && !event.ctrlKey && !event.altKey && !event.metaKey) {
      const game: Game2048 = this.game;
      const key = event.key.toLowerCase();
      let flag = true;
      if (key === 'arrowup' || key === 'w') {
        game.move(Direction.Up);
      } else if (key === 'arrowright' || key === 'd') {
        game.move(Direction.Right);
      } else if (key === 'arrowdown' || key === 's') {
        game.move(Direction.Down);
      } else if (key === 'arrowleft' || key === 'a') {
        game.move(Direction.Left);
      } else {
        flag = false;
      }
      if (flag) {
        event.preventDefault();
      }
    } else if (this.state.messageVisible) {
      const key = event.key.toLowerCase();
      if (key === ' ' || key === 'enter') {
        const gameMessage: HTMLDivElement | null = document.querySelector('div.game-message');
        if (gameMessage != null) {
          gameMessage.click();
          event.preventDefault();
        }
      }
    }
  };

  protected _moveG = (direction: number) => {
    if (this.game != null && !this.state.messageVisible) {
      if (direction === Hammer.DIRECTION_UP) {
        this.game.move(Direction.Up);
      } else if (direction === Hammer.DIRECTION_RIGHT) {
        this.game.move(Direction.Right);
      } else if (direction === Hammer.DIRECTION_DOWN) {
        this.game.move(Direction.Down);
      } else if (direction === Hammer.DIRECTION_LEFT) {
        this.game.move(Direction.Left);
      }
    }
  };
}

export default App;
